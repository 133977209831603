import api from './axiosConfig.js'

export default {
  getAll() {
    return api({
      method: 'get',
      url: '/projects/',
    })
  },
  getId(articleId) {
    return api({
      method: 'get',
      url: '/projects/' + articleId,
    })
  },
}
