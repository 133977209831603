<template>
  <h1 class="Title">Projects</h1>
  <Timeline
    :timeline-items="sortedProjects"
    :message-when-no-items="messageWhenNoItems"
    :add-link="timelineLink"
  />
</template>
<script>
import Timeline from '@/components/timeline/Timeline.vue'
import ProjectsAPI from '@/api/projects.js'

export default {
  name: 'Projects',
  components: {
    Timeline,
  },
  data() {
    return {
      projects: [],
      messageWhenNoItems: 'Loading projects...',
      timelineLink: true,
    }
  },
  created() {
    ProjectsAPI.getAll()
      .then((response) => (this.projects = response.data))
      .catch((error) => console.log(error))
  },
  computed: {
    sortedProjects() {
      return this.projects
        .slice()
        .sort((a, b) => new Date(a.enddate) - new Date(b.enddate))
    },
  },
}
</script>
<style></style>
